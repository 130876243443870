.ordersModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  z-index: 999;

  &__container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    z-index: 9999;

    width: 300px;
    height: 30%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__item {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      text-align: center;
    }
  }
}

.select {
  font-family: Inter;

  background-color: #fff;

  &.MuiOutlinedInput-notchedOutline {
    border-color: '#06c0b0';
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: '#06c0b0';
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: '#06c0b0';
  }
}