.contactChange {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  font-family: Inter;
  font-style: normal;
  line-height: normal;
  color: #575757;

  width: 95%;
  margin: 0 auto;

  &__title {
    display: flex;
    align-items: center;

    color: #007a6f;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &__back {
      cursor: pointer;
      margin-right: 10px;
      height: 30px;
      width: 30px;

      &:hover {
        //make rotate 360deg
        transform: rotate(360deg);
        transition: 0.5s;
      }
    }
  }

  &__body {
    width: 100%;
    height: 100%;
    &__top {
      // width: 100%;
      // height: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      @media screen and (max-width: 1440px) {
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
      }

      &__left {
        // width: 73%;
        &__top {
          display: flex;
          flex-wrap: wrap;
          // justify-content: space-between;
          justify-content: center;
          gap: 15px;
          margin-bottom: 20px;
        }

        &__bottom {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 15px;

          @media screen and (max-width: 768px) {
            justify-content: center;
          }

          &__item {
            // width: 45%;

            background-color: #fff;
            border-radius: 10px;
          }
        }
      }

      &__right {
        // width: 25%;
        height: 200px;
        display: flex;
        justify-content: center;

        border-radius: 10px;

        // min-height: 68.5vh;

        // background-color: #fff;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contactChange {
    &__body {
      &__top {
        &__left {
          width: 100%;
          &__bottom {
            width: 100%;
          }
        }
      }
    }
  }
}
