.ordersInfo {
  display: flex;
  flex-direction: row;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  &__item {
    display: flex;
    width: 30%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    align-items: center;

    &__content {
      &__title {
        font-family: 'Inter' !important;
        font-size: 25px !important;
      }

      &__text {
        font-family: 'Archivo Black' !important;
        font-size: 18px !important;
      }
    }
  }
}

@media screen and (max-width: 798px) {
  .ordersInfo {
    &__item {

      &__content {
        &__title {
          font-size: 18px !important;
        }

        &__text {
          font-size: 14px !important;
        }
      }
    }
  }

}