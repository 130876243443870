.additionalInfo__cc {
  width: 90%;
  margin: 0 auto;
  height: 95%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  gap: 5px;
  padding: 10px;
}
