.autorization {
  @media screen and (max-width: 768px) {
    &__content {
      &__title {
        width: 90%;
        font-size: 35px !important;
      }

      &__btns {
        width: 90% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
      }
    }
  }

  width: 95%;
  margin: 0 auto;

  &__content {
    &__title {
      margin-top: 10%;
      display: flex;
      align-items: center;
      color: #5fc56e;

      font-family: 'Archivo Black';
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      margin-bottom: 20px;

      width: 50%;
    }

    &__btns {
      width: 30%;
      display: flex;
      justify-content: space-between;

      &__btn {
        width: 200px;
        height: 50px;
        border: none;
        border-radius: 50px !important;

        font-family: 'Inter' !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;

        background-color: #5fc56e !important;
      }
    }

    &__modal {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fafafa;
      width: 300px;
      height: fit-content;
      padding: 5%;
      padding-bottom: 0;
      border-radius: 10px;
      flex-direction: column;
      gap: 2;

      &__content {
        text-align: center;
        width: 70%;
        margin: 0 auto;
      }

      h2 {
        font-size: 25px;
        color: #5fc56e;

        font-family: 'Archivo Black';
      }

      h5 {
        color: #186419;
        text-align: center;
        width: 90%;
        margin: 20px auto;

        font-size: 15px;
      }

      p {
        margin-top: 30%;
        margin-bottom: 10%;
        color: #a4a4a4;

        font-size: 10px;

        text-align: center;
        letter-spacing: 0.05em;
      }
    }
  }
}
