.topOrders {
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  max-width: 400px;

  &__btn {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    background-color: #fafafa;
    cursor: pointer;

    &.active {
      background-color: #06c0b0;
      color: #fff;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;

    &__users {
      display: flex;
      border-bottom: 1px solid #a4a4a4;
      padding-bottom: 10px;
      justify-content: space-between;

      margin: 15px 0;

      &__bottom {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      &.flex {
        display: flex;
      }

      &__item {
        p {
          margin: 0;
        }
        &__checkbox {
          display: flex;
          align-items: center;

          input {
            margin-right: 5px;
          }

          p {
            margin: 0;
          }
        }
      }
    }

    &__left {
      h2 {
        margin: 0;
        color: #575757;
        font-size: 16px;
        font-weight: 400;
      }
      &__bottom {
        display: flex;
        gap: 20px;
        &__item {
          display: flex;
          gap: 5px;

          &__cl {
            width: 20px;
            height: 20px;
            border-radius: 7px;
          }
          &__value {
            color: #a4a4a4;
          }
        }
      }
    }
  }
  &__bottom {
    display: flex;
    width: 100%;
    height: 70%;
    overflow-y: scroll;
    padding-right: 27px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */

    &__users {
      width: 100%;
      height: 78%;
      overflow: hidden;

      &__item {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
        box-sizing: content-box;
      }
    }

    &__left {
      width: 80%;
    }

    &__right {
      width: 20%;

      display: flex;
      align-items: center;
      // justify-content: flex-end;
      flex-direction: column;

      &__quantity {
        margin-top: 15px;
        height: 100%;
        display: flex;
        align-items: center;
        // justify-content: center;
        flex-direction: column;
        gap: 27px;
        &__item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          gap: 33px;

          border-radius: 10px;
          padding: 5px;

          & p {
            color: #fff;
            margin: 0;
          }
        }
      }
    }
  }
}
