.catalogChangeBox {
  width: 97%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  padding: 10px;
  border-radius: 10px;
  background-color: #CBDCCB;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: #2D2D2D;

      font-family: Archivo Black;
      font-size: 1em;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

    &.right {
      justify-content: end;
    }
  }
}