.custom-euro path {
  fill: color;
}

.statItem {



  font-family: 'Archivo Black',
    sans-serif;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 auto;

  width: 250px;

  padding: 20px;
  gap: 20px;

  // margin-bottom: 20px;

  border-radius: 14px;
  border: 0.2px solid #000;

  background: linear-gradient(180deg, #fff 99.99%, rgba(23, 99, 24, 0) 100%);

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &__titleItem {
    display: flex;

    // align-items: center;
    // justify-content: left;
    &__image {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      flex-shrink: 0;
    }

    &__title {
      // color: #176318;
      color: #176318;
      // font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 1.8px;

      text-align: left;
    }
  }

  &__value {
    color: #5cc3fd;

    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 4px;

    // text-align: center;
  }

  @media screen and (max-width: 768px) {
    width: 160px;
    height: 50px;
    padding: 10px;



    &__titleItem {
      display: flex;

      // align-items: center;
      // justify-content: left;
      &__image {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

      }

      &__title {
        color: #176318;
        font-size: 14px;
        text-align: left;
      }
    }

    &__value {
      color: #5cc3fd;
      font-size: 12px;
    }
  }
}