.createProduct {
  // overflow-y: scroll;
  &__container {
  }
  &__item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
