.filtersInfo {
  // width: 18%;
  display: flex;
  flex-direction: column;
  gap: 35px;

  &__rating {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    border-radius: 10px;
    padding: 10px;

    h3 {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
      margin-left: 5px;
    }

    &__box {
      display: flex;
      gap: 5px;
      padding: 5px;
    }
  }
  &__cats {
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    &__item {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      padding: 5px;

      &__like {
        cursor: pointer;

        &.active {
          fill: #00e471;
        }
      }

      &__dislike {
        cursor: pointer;

        &.active {
          fill: #fb547e;
        }
      }

      .line {
        position: absolute;
        top: 50%;
        left: 75%;
        transform: translateY(-50%);
        width: 1px;
        height: 50%;
        background-color: #ccc;
      }
    }
  }
  &__extra {
    padding: 10px;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    p {
      font-size: 16px;
      margin: 0;
    }
    div {
      padding: 5px 10px;
      background-color: #00e471;
      border-radius: 10px;
      color: #fff;
      text-align: center;
    }
  }
}
