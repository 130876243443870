.ordersChange {
  width: 95%;
  margin: 0 auto;

  margin-bottom: 100px;

  font-family: Inter;
  font-style: normal;
  line-height: normal;
  color: #575757;

  &__add {
    width: 280px;
    background-color: white;
    color: #176318;
    font-family: 'Montserrat';
    border: 1px solid #176318;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
    text-transform: uppercase;

    font-size: 0.9em;
    font-weight: 600;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    transition: all 0.3s;

    &:hover {
      background-color: #176318;
      color: white;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    justify-content: center;
    gap: 60px;

    margin-bottom: 25px;
  }
}
