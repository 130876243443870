.welcome-page {
  margin: 0 auto;
  width: 95%;
  height: 100vh;
  display: flex;

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;

    &__logo {
      display: flex;
      &__text {
        margin: 0;
        color: #5fc56e;
        font-family: 'Archivo Black';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        position: relative;

        p {
          position: relative;
          span {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 1px;
            font-size: 0.5em;
            color: #186419;
          }
        }
      }
    }
  }
}
