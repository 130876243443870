.usersItem {
  background-color: rgb(241, 241, 241);
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;

  margin-bottom: 10px;
  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__bottom {
    overflow-y: scroll;
    width: 100%;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
  }

  &__open {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 20px;
  }

  &__bottom {
    margin-left: 35px;
    height: 70%;
    display: flex;
    flex-direction: column;

    &__item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
