.peopleInfo {
  color: #176318;

  font-family: Archivo Black;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  align-items: center;

  gap: 35px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 30px;

    border-radius: 7px;
    border: 0.2px solid #000;
    background: linear-gradient(180deg, #fff 99.99%, rgba(23, 99, 24, 0) 100%);
    box-shadow: 0px 4px 4px 0px rgba(23, 99, 24, 0.25);

    position: relative;

    img {
      border-radius: 50%;
    }

    &__down {
      transform: rotate(180deg);
      transition: transform 0.3s ease-in-out;

      cursor: pointer;

      padding: 5px;
      position: absolute;
      top: 0;
      right: -50px;

      // right: 0;
      .active {
        transition: transform 0.3s ease-in-out;
      }
    }

    &__change {
      width: 100%;

      &:hover {
        cursor: pointer;
        color: #06c0b0;
        transition: color 0.3s ease-in-out;
        background-color: #f0f0f0;
      }
    }

    h4 {
      margin: 0;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .peopleInfo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // flex-direction: column;
    gap: 10px;
  }

}