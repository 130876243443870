.history {
  &__title {
    font-size: 35px !important;
    font-weight: 600;
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 20px !important;
    }
  }
}
