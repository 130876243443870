.totalInfo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;

  font-family: Archivo Black;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &__status {
    color: #176318;

    font-family: Archivo Black;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 5px 10px;

    border-radius: 7px;
    border: 0.2px solid #000;

    background: #FFF;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    margin-bottom: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    border-radius: 8px;
    background: #e5ede5;
    padding: 10px;
    //box shadow with darg green color
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    gap: 15px;

    &__item {
      width: 100%;
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      // gap: 10px;
      // margin: 15px 0;

      & .total {
        color: #007a6f;
        font-size: 24px;
      }

      h5 {
        margin: 0;
        color: #1e1d28;
        font-size: 18px;
        color: #767676;
      }

      span {
        color: #000;

        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  &__btns {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 10px;
    height: 26px;
    margin-top: 30px;
    border-radius: 10px;

    & .save {
      background-color: #06c0b0;
      color: #fff;

      &:hover {
        background-color: #06c0b0;
        color: #fff;
      }
    }

    & button {
      font-family: Archivo Black;
      font-size: 0.8em;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 10px 15px;
      border-radius: 6px;
      border: 0px solid #176318;
      color: #fff;
      background: #176318;
      text-transform: uppercase;

      align-items: center;
      justify-content: center;
      display: flex;

      &:hover {
        background-color: #c2d6d4;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .totalInfo {
    &__container {
      margin: 0 auto;
    }

    &__statuses {
      width: 90% !important;
      margin: 0 auto;
    }
  }

}