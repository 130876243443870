.staticPage {
  width: 95%;
  margin: 0 auto;

  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: 'linear-gradient(180deg, #fff 51.04%, #176318 100%)';

  &__header {
    font-family: 'Inter';

    &__title {
      font-family: 'Archivo Black';
      @media screen and (max-width: 768px) {
        font-size: 20px !important;
        text-align: center !important;
      }
    }
  }
}
