.orderInfo {
  display: flex;
  margin-top: 10px;
  gap: 10px;

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    // position: relative;
    border: 0px solid #000;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.25);

    &__item {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      /* Hide the scrollbars */

      ::-webkit-scrollbar {
        width: 0.4em;
        /* Width of the scrollbar */
      }

      ::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        /* Color of the thumb */
        border-radius: 3px;
        /* Rounded corners of the thumb */
      }

      ::-webkit-scrollbar-track {
        background-color: #ffffff;
        /* Color of the track */
      }

      p {
        color: #787878;

        font-family: Archivo Black;
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        margin: 0;

        span {
          color: #000;

          font-family: Inter;
          font-size: 0.9em;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .orderInfo {
    flex-direction: column;
    background-color: #ecfae8;
    border-radius: 10px;
    padding: 10px;

    &__box {
      width: 90%;
      border: none !important;

      box-shadow: none !important;
    }
  }
}
