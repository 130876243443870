.tableModal {
  width: 95%;
  height: 70px;
  display: flex;
  position: fixed;
  bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  color: #06c0b0;

  // margin-top: 60px;


  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  & .vertical__line {
    width: 1px;
    height: 100%;
    background-color: #d9d9d9;
  }

  .tableModal__title {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter;
  }

  .tableModal__body {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &__btn {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 20px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #06c0b0;
      color: #fff;

      &:hover {
        cursor: pointer;
        background-color: rgb(0, 171, 85);
        color: #fff;
        border-radius: 5px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }

    &__item {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 5px;

      & .red {
        color: rgb(251, 84, 126);
      }

      & .green {
        color: rgb(0, 171, 85);
      }

      & .black {
        color: rgb(0, 0, 0);
      }

      &:hover {
        cursor: pointer;
        background-color: #06c0b0;
        color: #fff;
        border-radius: 5px;
      }

      p {
        margin: 5px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tableModal {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 0;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0;
    }

    &__title {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        text-align: center;
      }
    }


    &__body {
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      margin: 0 auto;


      &__item {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        p {
          margin: 5px;
          text-align: center;
        }
      }

      &__btn {
        width: 100% !important;
        border-radius: 6px !important;
        height: 40px !important;

        position: relative !important;
        margin: 15px auto;
        top: 0 !important;
        right: 0 !important;
        // transform: translate(0%, 0%) !important;
      }
    }

    .vertical__line {
      display: none;
    }


  }

}