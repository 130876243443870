.progress {
  color: rgba(0, 0, 0, 0.8);

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 15px;

    &__item {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  .progress {
    font-size: 14px;
    margin-bottom: 20px;
    // align-items: left;

    &__top {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
