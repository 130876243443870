.personalSales {
  margin-left: 10px;

  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  padding: 10px;

  height: max-content;

  border-radius: 10px;
}
