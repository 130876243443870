.stats {
  margin-bottom: 50px;
  margin-top: 50px;

  position: relative;

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    @media screen and (max-width: 768px) {
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }

    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;
    grid-gap: 10px;
  }

  &__close {
    cursor: pointer;
    display: flex;
    align-items: center;

    &__title {
      margin-right: 10px;
      color: #176318;
      letter-spacing: 2px;
      // font-family: Archivo Black;
      font-size: 22px;
      font-style: bold;
      font-weight: 1000;
      text-transform: uppercase;
    }

    &__arrow {
      transition: all 0.3s;

      &.open {
        transform: rotate(0deg);
      }

      &.close {
        transform: rotate(180deg);
      }
    }
  }
}