.tableHeader {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &__title {
    color: #000;

    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: flex;
    align-items: center;

    width: 80%;
  }

  &__body {
    display: flex;

    justify-content: flex-end;
    align-items: center;

    &__btn {
      width: 130px;
      height: 40px;
      flex-shrink: 0;
      border: 1px solid #176318e6;
      border-radius: 7px;
      background-color: #fff;

      color: #176318e6;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.4px;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      &:hover {
        background-color: #c2d6d4;
      }

      &.active {
        border-radius: 6px;
        border: 0px solid #176318;
        color: #fff;
        background: #176318;
        color: #f5f5f7;

        font-family: Archivo Black;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &__search {
      display: flex;
      align-items: flex-end;
      position: relative;
      bottom: 10px;
      width: 250px;
      justify-content: center;

      margin-right: 50px;
      margin-left: 50px;

      margin-top: 15px;

      span {
        position: absolute;
        right: 0px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      input {
        width: 100%;
        height: 36.4px;
        flex-shrink: 0;
        padding-left: 20px;
        border-radius: 10px;
        border: 1px solid #e8e8e8;
        background: #fff;
        text-align: left;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 1.4px;
      }

      input::placeholder {
        color: #c2c2c2;

        text-align: left;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 1.4px;
      }

      input:focus {
        outline: none;
      }

      input:hover {
        background: rgba(25, 118, 210, 0.04);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }

  .tableHeader {
    &__title {
      display: none;
    }

    &__body {
      width: 100%;
      margin-bottom: 10px;
      position: relative;

      &__search {
        margin: 0;
        margin: 0 20px;

        bottom: 0;

        width: 30%;
      }

      &__btn {
        width: 5%;

        font-size: 10px !important;

        label {
          font-size: 14px !important;
        }

        p {
          display: none;
        }
      }
    }
  }
}
