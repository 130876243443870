.catalogPrice {
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  width: 95%;
  margin: 0 auto;

  background-color: #fff;

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 25px;

    background-color: #176318;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;

    padding: 10px;

    // margin-bottom: 20px;

    color: #176318;

    font-family: Archivo Black;
    font-size: 0.9em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

    &__btn {
      // background-color: #f5f5f5;
      padding: 5px 10px;
      border-radius: 10px;

      border-radius: 6px;
      border: 0px solid #176318;

      background: #cbdccb;

      &.active {
        // background-color: #06c0b0;
        background: #0e3c0f;

        color: #fff;
      }

      &:hover {
        background-color: #218723;
        color: #fff;

        &.active {
          background-color: #0e3c0f;
          color: #fff;
        }

        cursor: pointer;
      }
    }
  }

  &__body {
    width: 100%;
    background: linear-gradient(#e5ede5 0%, rgba(229, 237, 229, 0) 100%);
    padding: 20px 10px;

    &__title {
      color: #176318;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      &__box {
        width: 30%;
        display: flex;
        justify-content: space-between;
        background-color: #f5f5f5;
        border-radius: 10px;

        &__item {
          width: 50%;
          padding: 10px;
          border-radius: 10px;
          background-color: #f5f5f5;
          text-align: center;
        }

        & .active {
          border-radius: 7px;
          border: 0.2px solid rgba(0, 0, 0, 0);

          background: linear-gradient(180deg, #cbdccb 99.99%, rgba(23, 99, 24, 0) 100%);

          box-shadow: 0px 4px 4px 0px rgba(23, 99, 24, 0.25);
        }
      }

      &__item {
        width: 10%;
        padding: 10px;
        border-radius: 10px;
        background-color: #f5f5f5;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &__item {
        margin-top: 20px;
        width: 10%;
        display: flex;
        padding: 10px;
        border-radius: 7px;
        border: 0.2px solid rgba(0, 0, 0, 0);

        background: linear-gradient(180deg, #fff 99.99%, rgba(23, 99, 24, 0) 100%);

        box-shadow: 0px 4px 4px 0px rgba(23, 99, 24, 0.25);

        color: #000;

        &.title {
          width: 28%;
          padding: 10px;
          border-radius: 10px;
          background-color: #f5f5f5;
          display: flex;
          align-items: center;
          justify-content: center;

          color: #176318;

          font-family: Archivo Black;
          font-size: 1em;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
        }
      }
    }
  }

  &__footer {
    width: 100%;
    padding: 10px;

    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    background-color: #176318;

    &__btn {
      width: 40%;
      padding: 10px;
      border-radius: 6px;
      border: 0px solid #176318;

      background: #cbdccb;
      text-align: center;

      color: #176318;

      font-family: Archivo Black;
      font-size: 1em;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;

      &:hover {
        background-color: #124b13;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
