.changeBuisness {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #000;
    font-weight: 600;
    font-size: 18px;
  }

  &.active {
    font-weight: 600;
    font-size: 18px;
    color: #000;
    background-color: #a4a4a4;

    &:hover {
      color: #000;
      font-weight: 600;
      font-size: 18px;
      background-color: #a4a4a4;
      text-decoration: none;
    }
  }
}

.info {
  display: flex;
  background-color: #fff;
  padding: 10px;
  gap: 10px;

  flex-wrap: wrap;

  // width: 55%;

  border-radius: 10px;

  &__left {
    display: flex;
    flex-direction: column;
    // width: 40%;
    box-shadow: 0px 4px 4px 0px rgba(23, 99, 24, 0.25);
    padding: 20px;
    border-radius: 10px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__name {
        color: #a4a4a4;
        font-size: 16px;
        margin: 10px 0;
      }

      &__value {
        margin: 0;
        font-size: 18px;
        max-width: 60%;
        text-align: right;

        &.buisness {
          background-color: #c2bdbd;
          border-radius: 10px;
          padding: 5px 10px;
          cursor: pointer;
        }
      }
    }
  }

  &__right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // width: 60%;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(23, 99, 24, 0.25);
    border-radius: 10px;
    &__minus {
      position: absolute;
      width: 80%;
      height: 1px;
      background-color: #a4a4a4;

      left: 50%;
      transform: translateX(-50%);
    }

    &__line {
      position: absolute;
      width: 1px;
      height: 80%;
      background-color: #a4a4a4;

      top: 50%;
      transform: translateY(-50%);
    }

    &__item {
      width: 40%;
      height: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &__info {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 20px;
          font-weight: 500;
          color: #575757;
        }
      }

      &__name {
        color: #a4a4a4;
        font-size: 16px;
        align-self: flex-start;
        margin-left: 10%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__left {
      &__item {
        &__value {
          font-size: 16px;
        }
      }
    }
  }
}
