.rentabilidad {
  width: 30%;
  background-color: #fff;

  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(23, 99, 24, 0.25);

  &__container {
    display: flex;
    width: 100%;

    &__left {
      display: flex;
      flex-direction: column;
      width: 20%;

      &__info {
        margin: 0;
        &__value {
          margin: 0;
          &:last-child {
            margin-bottom: 10px;
          }
          font-size: 20px;
          font-weight: 500;
          &.orange {
            color: #ff9f00;
          }
          &.purple {
            color: #6f6bf4;
          }
        }
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      width: 80%;
    }
  }

  h2 {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
