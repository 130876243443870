.catalogInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }

  p {
    margin: 0;
    color: #787878;

    font-family: Archivo Black;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-transform: uppercase;
  }

  &__left {
    width: 40%;
    height: 30vh;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    border-radius: 10px;
    border: 1px solid #176318;
  }

  &__right {
    @media screen and (max-width: 768px) {
      width: 100%;
      gap: 10px;
    }
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        width: 30%;
      }
    }
  }
}
