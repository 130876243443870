.topOrder {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__image {
    width: 50px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 5px;
  }
  &__name {
    width: 90%;
    overflow: hidden;
  }
}
