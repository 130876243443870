.user {
  display: flex;
  // align-items: center;
  justify-content: center;

  background-color: #b4b8da;
  border-radius: 10px;
  padding: 10px;

  // width: 17%;

  &__left {
    width: 90%;
    display: flex;
    flex-direction: column;
    &__img {
      width: 150px;
      height: 150px;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      margin-bottom: 5px;
    }
    &__info {
      display: flex;
      flex-direction: column;

      p {
        margin: 0;
      }

      div {
        display: flex;
        justify-content: space-between;
        p {
          margin: 5px 0;
          font-size: 14px;
          padding: 3px;
          border-radius: 10px;
          background-color: #d9d9d9;

          &.active {
            background-color: #06c0b0;
            color: #fff;
          }
        }
        input {
          align-self: center;
        }
      }
    }
  }

  &__right {
    width: 10%;
    margin: 0 20px;
    display: flex;
    align-items: flex-start;
    // justify-content: flex-start;
    flex-direction: column;

    svg {
      margin-bottom: 10px;

      padding: 5px;

      border-radius: 15px;

      &.active {
        border: 2px solid #06c0b0;
      }
    }
  }
}
