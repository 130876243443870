.catalogChange {
  width: 95%;
  margin: 0 auto;

  font-family: Inter;

  &__title {
    display: flex;
    align-items: center;
    color: #176318;

    font-family: Archivo Black;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 1440px) {
      flex-direction: column;
      gap: 20px;
    }

    &__left {
      @media screen and (max-width: 1440px) {
        width: 100%;
      }

      width: 40%;
    }

    &__right {
      @media screen and (max-width: 1440px) {
        width: 100%;
      }

      width: 60%;
    }
  }
}
