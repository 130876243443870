.information {
  display: flex;
  flex-direction: column;
  // justify-content: center;

  width: 250px;

  background-color: #fff;
  border-radius: 10px;

  padding: 10px;

  h3 {
    letter-spacing: 1.8px;
    font-size: 18px;
    color: #06c0b0;
    padding-bottom: 4px;
    border-bottom: #e1e1e1 1px solid;
    width: 40%;
    margin: 0;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    h4 {
      margin: 0;
    }
  }
}
