.margen {
  width: 200px;
  max-height: 268px;
  padding: 15px;

  border-radius: 10px;

  box-shadow: 0px 4px 4px 0px rgba(23, 99, 24, 0.25);

  background-color: #fff;
  p {
    font-size: 16px;
    margin: 0;
  }
}
