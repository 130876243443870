.itemStatus {
  position: absolute;
  top: 0;
  left: 25px;

  transform: translate(0%, -100%);

  width: max-content;
  height: max-content;
  background: #ffffff;
  z-index: 999;

  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;

  padding: 10px;
  // gap: 10px;

  border-radius: 0px 14px 14px 14px;
  border: 1px solid #2a2d2f;
  box-shadow: -14px -3px 7.6px 0px rgba(23, 99, 24, 0.25);

  &__add {
    width: 70px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 1px;
    cursor: pointer;
    border-radius: 13px;
    border: 0px solid #46ad1e;
    background: #46ad1e;
    color: #f5f5f7;
    font-family: 'Inter';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 50px;

    &:hover {
      cursor: pointer;
      background-color: #007a6f;
      color: #000;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    // justify-content: center;
    margin-bottom: 10px;
    padding: 3px 5px;
    border-radius: 2px;

    &:hover {
      cursor: pointer;
      border-radius: 2px;
      background: rgba(36, 158, 38, 0.8);
    }

    &.active {
      background: rgba(23, 99, 24, 0.8);
    }

    p {
      padding: 0;
      margin: 0;
    }

    span {
      margin-left: 5px;
      position: relative;

      color: #2a2d2f;

      font-family: Archivo Black;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

//for link in orders
.tableBodyItem__item__link {
  &:hover {
    text-decoration: underline !important;
    color: #46ad1e !important;
    cursor: pointer !important;
  }
}

@media screen and (max-width: 768px) {
  .itemStatus {
    // display: flex !important;
    width: 40vh;
    transform: translate(0%, -50%);
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    height: 5em;
    overflow-y: scroll;
    justify-content: space-between;
  }
}

.tableBodyItem {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 50px;
  width: 100%;

  background-color: #ecffed;

  &:hover {
    background-color: #dbf9dc !important;

    &__item {
      &.platform {
        background-color: #dbf9dc;
      }
    }
  }

  .more {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;

    &:hover {
      cursor: pointer;
      background-color: #8f8f8f;
      border-radius: 5px;
    }

    cursor: pointer;

    font-size: large;
  }

  .status {
    // color: #fff;
    font-size: 16px;

    width: 100px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    border-radius: 7px;
    border: 1px solid #000;
    padding: 5px 0;
  }

  .platform {
    display: flex;
    justify-content: center;
    align-items: center;

    &[data-tooltip] {
      &:after {
        display: none;
      }
    }

    @media screen and (max-width: 768px) {
      &[data-tooltip] {
        &:after {
          display: block;
          margin-left: 100%;
          // width: 120px;
        }
      }
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__item {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.6px;
    text-align: center;

    span.hover {
      &:hover {
        background-color: #d6fad7;
        padding: 10px 0;
      }
    }

    &.Picking {
      background-color: rgb(119, 122, 229);
      color: #fff;
    }

    &.Entregado {
      background-color: rgb(113, 214, 209);
      color: #fff;
    }

    &.DeCargar {
      background-color: rgb(64, 22, 148);
      color: #fff;
    }

    &.DeCamino {
      background-color: rgb(213, 197, 103);
      color: #fff;
    }

    &.Cancelado {
      background-color: rgb(255, 68, 161);
      color: #fff;
    }

    &.Facturado {
      background-color: rgb(53, 153, 112);
      color: #fff;
    }

    // width: min-content;

    span {
      overflow: hidden;
      width: 100%;
      // max-width: 100px;
      white-space: nowrap;
      display: inline-block;

      text-align: center;

      // width: 100px;
    }

    @media screen and (max-width: 1550px) {
      span {
        // width: 80px;
        // max-width: 80px;
        font-size: 14px;
      }
    }

    // @media screen and (max-width: 1300px) {
    //   span {
    //     // width: 60px;
    //     // max-width: 60px;
    //     font-size: 12px;
    //   }
    // }

    // @media screen and (max-width: 1000px) {
    //   span {
    //     // width: 40px;
    //     // max-width: 40px;
    //     font-size: 10px;
    //   }
    // }

    &:hover {
      &[data-tooltip]:after {
        opacity: 1;
        z-index: 10;
      }
    }

    &[data-tooltip]:after {
      background: #007a6f;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      color: #fff;
      content: attr(data-tooltip);
      left: 0;
      opacity: 0;
      padding: 0.5em;
      pointer-events: none;
      position: absolute;
      top: 20px;
      transition: 0.3s;
      width: max-content;
    }
  }
}
