header {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0 0 10px 10px;
}

.header {
  width: 95%;
  margin: 0 auto;
  display: flex;
  height: 60px;

  justify-content: space-between;
  align-items: center;

  &__logo {
    // width: 200px;
    display: flex;
    align-items: flex-end;

    h1 {
      margin: 0;
      margin-left: 10px;
      font-family: 'Archivo Black';
      font-weight: 400;
      font-size: 20px;
      color: #186419;
    }

    @media screen and (max-width: 1260px) {
      // display: none;

      font-size: 16px;
      width: 160px;

      h1 {
        font-size: 16px;

        span {
          font-size: 16px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      // display: none;

      font-size: 14px;
      width: 140px;

      h1 {
        display: none;

        span {
          font-size: 14px;
        }
      }
    }
  }

  &__menu {
    display: flex;
    justify-content: center;

    &__burger {
      display: none;

      @media screen and (max-width: 1260px) {
        font-family: "Inter", sans-serif;
        top: 0;
        left: 0;
        position: absolute;
        // padding: 1em;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        background-color: white;

        width: 100vw;
        height: 100vh;
        z-index: 999;
        overflow-y: scroll;

        &__head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 50px;
          border-bottom: 1px solid #E6E6E6;
          gap: 10px;
          padding: 10px;
          box-sizing: border-box;
          margin-bottom: 20px;

          &__logo {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            font-family: "Archivo Black", sans-serif;
            font-weight: 400;
            font-size: 20px;
            color: #186419;
          }
        }

        &__account {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;

          padding: 10px;
          width: 100%;
          box-sizing: border-box;
          border-bottom: 1px solid #E6E6E6;
          margin-bottom: 36px;

          &__image {
            border: 1px solid #e6e6e6;
            border-radius: 10px;
            width: 50px;
            height: 50px;
            background: #e6e6e6;

            font-family: "Inter", sans-serif;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            color: #176318;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &__name {
            font-weight: 300;
            font-size: 20px;
            text-align: center;
          }
        }
        
        &__body {
          gap: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 10px;
          box-sizing: border-box;
          margin-bottom: 40px;

          &__item {
            gap: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;  
            justify-content: flex-start;
            width: 100%;

            &__title {
              font-weight: 500;
              font-size: 16px;
              color: #474747;
            }
            &__lists {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 100%;
              padding: 5px 0;
              flex-wrap: wrap;
              gap: 10px;

              &__item {
                font-weight: 500;
                font-size: 14px;
                flex: 1 1;
                flex-basis: 80px;
                text-align: center;
                color: #919191;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;
                cursor: pointer;
                
                &:hover {
                  animation: pulse 0.5s ease-in-out alternate;
                }
              }
            }
          }
        }
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 60px;
      border-radius: 8px;
      padding-right: 20px;
      padding-left: 20px;

      font-family: "Archivo Black", sans-serif;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #4c4c4c;

      &:hover {
        background-color: #c2d6d4;
        cursor: pointer;
      }

      &.active {
        background-color: #e6f2e6;
        color: #176318;
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        fill: #176318e6;
        stroke: #176318;

        margin-top: 5px;

        margin-left: 15px;
      }

      &__open {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        gap: 5px;

        overflow: hidden;
        flex: 1;
        flex-basis: 90px;
        height: 70px;
        &:hover {
          animation: pulse 0.5s ease-in-out alternate;
        }

        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #4c4c4c;

        cursor: pointer;

        &__title {
          font-family: "Inter", sans-serif;
          font-weight: 500;
          font-size: 12px;
          color: #474747;
          margin-bottom: 10px;
        }
        &__operations {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: fit-content;
          gap: 10px;
        }
      }


      @media screen and (max-width: 1260px) {
        // display: none;

        font-size: 16px;
        padding-right: 16px;
        padding-left: 16px;
      }

      @media screen and (max-width: 1100px) {
        // display: none;

        font-size: 14px;
        padding-right: 14px;
        padding-left: 14px;
      }
    }

    //hamburger
    input[type='checkbox'] {
      display: none;
    }

    .lines {
      display: none;
    }

    @media screen and (max-width: 1260px) {
      &__item {
        display: none;
      }

      &__lines {
        width: 30px;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        .line {
          width: 30px;
          height: 3px;
          background-color: #176318;
          border: #176318 solid 1px;
          border-radius: 3px;
          content: '';
        }
      }

      input[type='checkbox'] {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        z-index: -1;
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: center;

    &__logoutButton,
    &__logoutButton:hover {
      color: #176318;
    }

    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      flex-shrink: 0;
      padding-right: 20px;
      padding-left: 20px;
    }

    &__lang {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      flex-shrink: 0;
      padding-right: 20px;
      padding-left: 20px;

      p {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        margin-right: 10px;
      }
    }
  }
}

.content-wrapper {
  animation: scroll-left 15s linear infinite; /* Animation will target this element */
  display: inline-block; /* Needed to allow content to flow horizontally */
}

@keyframes scroll-left {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
