.tableBody {
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  &__more {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    border-radius: 10px;

    background-color: #d9d9d9;
    padding: 10px;

    opacity: 0.7;

    font-family: Inter;
    font-size: 20px;
    margin-top: 20px;

    color: gray;

    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-around;

    width: 100%;
    height: 50px;

    // background-color: #06c0b0;
    background-color: #176318e6;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &__checkbox {
      position: relative;
      top: 1px;
      width: 24px;
      height: 24px;
      background: #ffffff;
      border: 2px solid #d9d9d9;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;

      cursor: pointer;

      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;

      &.like {
        background-image: url('../../../assets/svg/CheckBoxLike.svg') !important;
      }

      &:checked {
        background-image: url('../../../assets/svg/Check.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-color: #06c0b0;

        border: 2px solid #fff;
      }
    }

    &__item {
      font-size: 16px;
      font-weight: 400;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.6px;
      color: #fff;
      text-align: center;

      width: 100px;

      @media screen and (max-width: 1550px) {
        // width: 80px;
        // max-width: 80px;
        font-size: 14px;
        overflow: hidden;
      }

      // @media screen and (max-width: 1300px) {
      //   // width: 60px;
      //   // max-width: 60px;
      //   font-size: 12px;
      //   overflow: hidden;
      // }

      // @media screen and (max-width: 1000px) {
      //   // width: 40px;
      //   // max-width: 40px;
      //   font-size: 10px;
      //   overflow: hidden;
      // }
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.chooseVisibleColumnsBlock {
  position: relative;

  span {
    overflow: auto;
    width: 157px;
    height: 212px;
    border-radius: 7px;
    background: #ffffff;
    position: absolute;
    left: -133px;
    top: 27px;
    color: #007a6f;
    font-size: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1px;
    text-align: start;
    padding: 5px;
    z-index: 99;

    div {
      margin-top: 5px;
    }
  }
}

.chooseVisibleColumns {
  background: #ffffff;
  color: #007a6f;
  border-radius: 7px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  position: relative;
  top: 1px;
}

.chooseVisibleColumns:hover {
  color: #4c4c4c;
}

.checkbox {
  position: relative;
  top: 1px;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  cursor: pointer;

  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;

  &.active {
    background-image: url('../../../assets/svg/Check.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #06c0b0;

    border: 2px solid #fff;
  }

  &:checked {
    background-image: url('../../../assets/svg/Check.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #06c0b0;

    border: 2px solid #fff;
  }
}

@media screen and (max-width: 768px) {
  .tableBody {
    overflow-x: scroll;
    overflow-y: hidden;
    

    &__title {
      width: 1600px;
      // display: none;
    }
    &__title__picker {
      width: 100% !important;
    }

    &__more {
      display: flex;
      justify-content: center;
      margin: 0 auto;

      color: gray;
    }
    .tableBody__title__picker {
      width: 100% !important;
    }
    .tableBodyItem {
      position: relative;

      &__item {
        &.platform {
          position: sticky;
          top: 0;
          left: 15%;
          background-color: #ecffed;
          z-index: 9;

          &:hover {
            background-color: #dbf9dc;
          }
        }
      }
    }
    .tableBody__title__picker {
        position: relative;

        &__item {
          &.platform {
            position: sticky;
            top: 0;
            left: 15%;
            background-color: #ecffed;
            z-index: 9;

            &:hover {
              background-color: #dbf9dc;
            }
          }
        }
      }
    &__body {
      width: 1600px;
      

      // .tableBodyItem {
      //   flex-wrap: wrap;
      //   gap: 20px;
      //   position: relative;

      //   border: 1px solid #2a2d2f;
      //   border-radius: 7px;

      //   margin-bottom: 20px;

      //   .tableBody__title__checkbox {
      //     // display: absolute;
      //     position: absolute;

      //     top: 1em;
      //     left: 1em;

      //     z-index: 999;
      //   }

      //   & .mobileid {
      //     width: 100% !important;
      //     // background-color: red;
      //     border-radius: 7px;
      //     padding: 1em;

      //     span {
      //       font-size: 14px;
      //     }
      //   }

      //   & .mobileStatus {
      //     position: absolute !important;
      //     top: 1em;
      //     right: 1em;

      //     width: 20% !important;
      //   }

      //   .more {
      //     display: none;
      //   }

      //   &__item {
      //     width: 30% !important;

      //     // pointer-events: none;

      //     span {
      //       text-align: center !important;
      //       font-size: 12px !important;
      //       padding: 0 !important;

      //     }

      //     &__mobileTitle {
      //       margin: 0 auto;
      //       margin-bottom: 5px;
      //       color: #787878;

      //       font-family: Archivo Black;
      //       text-align: center;
      //       // padding: 12px;
      //       width: 100%;
      //     }

      //   }
      // }
    }
  }
}
