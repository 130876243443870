.dashboard {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &__left {
    width: 65%;
  }

  &__right {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .dashboard {
    flex-direction: column;

    &__left {
      width: 100%;
    }

    &__right {
      width: 100%;
    }
  }
}